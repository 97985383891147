﻿@font-face {
  font-family: 'Din-Condensed';
  src: url('/assets/fonts/DINWeb-CondBlack.woff') format('woff'), url('/assets/fonts/DINOT-CondBlack.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Din-Condensed';
  src: url('/assets/fonts/DINWeb-CondBold.woff') format('woff'), url('/assets/fonts/DINOT-CondBold.otf') format('opentype');
  font-weight: 400;
}
