﻿@import 'partials/colors';
ul.nav.nav-tabs {
  margin: 0 auto;
  font-family: 'Din-Condensed';
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  border-style: none;

  & > li {
    background: #ccc;
    display: inline-block;
    float: none;

    &:last-child {
      border-bottom-right-radius: 45px;
      border-top-right-radius: 45px;
    }

    &:first-child {
      border-bottom-left-radius: 45px;
      border-top-left-radius: 45px;
    }

    & > a {
      padding: 5px 10px;
      margin-right: 0px;

      & > span {
        color: white;
      }

      &:hover,
      &:focus {
        background: #ccc;
        border: 1px solid #ccc;
        border-radius: 45px;
      }
    }

    &.active {
      & > a {
        background-color: $primary-blue;
        border-radius: 45px;
        border-bottom-color: #ccc;

        &:hover,
        &:focus {
          background: $primary_blue;
          border-radius: 45px;
          border-bottom-color: #ccc;
        }

        & > span {
          color: white;
        }
      }
    }
  }
}
.tab-content {
  padding: 20px;
  border: 1px solid #ccc;
  margin-top: -15px;
}
