﻿// Primary Colors
$relay-green:       #abd037;
$orange:            #FBB710;
$red:               #db4437;
$yellow:            #f7d64d; // status color
$violet:            #8f8be6; // status color
$red-orange:        #f49916; // status color

// Blues
$light-blue:        #e0eef5;
$boston-blue:       #3693bf;
$picton-blue:       #3FA9DD;
$slate-blue:        #546e7a;
$slate-blue-dark:   #37474f;

// Grayscale
$white:             #FFFFFF;
$light-gray-0:      #f9f9f9;
$light-gray-1:      #f3f4f4;
$light-gray-2:      #d7dadb;
$light-gray-3:      #CCCCCC;
$light-gray-4:      #e8e5e5;
$light-gray-5:      #9c9496;
$dusty-gray:        #999999;
$middle-gray:       #808080; // the rightful "gray"
$concord-gray:      #7F7E7D;
$ironside-gray:     #696960;
$chicago-gray:      #5B5A59; // the current "gray"
$dark-gray:         #231F20;
$black:             #000000;

// From Marketing Site

// Industry
$white: #ffffff;
$gray0: #fefefe;
$gray1: #fafafa;
$gray2: #eeeeee;
$gray3: #dddddd;
$gray4: #999999;
$gray5: #666666;
$gray6: #444444;
$black: #333333;
$true-black: #000000;

$green: #a7ce3c;
$green-dark: #90b421;
$green-darker: #82a027;

$industry-yellow: #ffd008;
$cyan: #00d5f1;

$blue: #316384;
$blue: #034b5f;
$blue-dark: #0f4350;

$vorange-lightest: #ffe3b3;
$vorange-lighter: #ffc883;
$vorange-light: #f99f35;
$vorange: #f48538;
$vorange-dark: #e46704;
$vorange-darker: #a04a01;

$vteal-lightest: #cce9e8;
$vteal-lighter: #94dedd;
$vteal-light: #69d0dd;
$vteal: #27bbcf;
$vteal-dark: #0a9aa1;
$vteal-darker: #00636c;

$vblue-lightest: #c8eafa;
$vblue-lighter: #98d7f4;
$vblue-light: #239ccc;
$vblue: #028ed1;
$vblue-dark: #3b66a7;
$vblue-darker: #0f4778;

$vgreen-lightest: #dcf2b6;
$vgreen-lighter: #ade5a2;
$vgreen-light: #3cd384;
$vgreen: #1cbb6f;
$vgreen-dark: #2d986e;
$vgreen-darker: #07674c;

$vpurple-lightest: #e7e0ff;
$vpurple-lighter: #d2c2ff;
$vpurple-light: #aa85e6;
$vpurple: #a06ce0;
$vpurple-dark: #645bbc;
$vpurple-darker: #4c3488;

$vred-light: #ffdbd5;
$vred-lighter: #ffb5ad;
$vred-light: #ee8b7b;
$vred: #ef6259;
$vred-dark: #c53a26;
$vred-darker: #7e0119;

//twoway
$message-blue: rgba(72, 150, 255, 0.2);
$message-grey: rgba(5, 5, 5, 0.1);

// Functional Names
$primary:           $slate-blue-dark;
$light_primary:     $light-blue;
$secondary:         $slate-blue;
$accent:            $relay-green;
$arrow:             $ironside-gray;
$primary_blue:      $boston-blue;
$primary-hover:     $picton-blue;
$input_gray:        $light-gray-3;
$gray:              $chicago-gray;
$title_gray:        $dusty-gray;
$report_gray:       $middle-gray;
$sub_title_gray:    $concord-gray;
$background_gray:   $light-gray-1;
$inactive_gray:     $light-gray-2;
$label_gray:        $chicago-gray;
$dark_border_gray:  $chicago-gray;
$border_gray:       $light-gray-2;