/**
Example Use
 
<div class="rn-multi-select">
    <mat-form-field class="select" appearance="outline">
        <mat-label>Select an industry</mat-label>
        <mat-select [formControl]="industryChoices" multiple>
        <mat-option class="select-option" *ngFor="let industry of industries" [value]="industry.name">{{industry.name}}
        </mat-option>
        </mat-select>
    </mat-form-field>
</div>
 */ 

// custom styles for material multi select
@import 'partials/colors';

.rn-multi-select {
    /**
     * text/floating label/border Styles
     */
   
    // limits select box width to 300px
    .mat-form-field {
        width: 300px;
    }

    // positions text 
    .mat-form-field-infix {
        height: 35px;
        border-top: 0;
        padding: .8em 0 1em 0;
    }

    // adjusts border height
    .mat-form-field-appearance-outline .mat-form-field-outline {
      top: 0;
    }

    // hides the "floating label" text
    .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, 
    .mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
        transform: scale(0.0);
        width: 0%;
    }

    // Material creates the border by creating 3 separate divs, all lined up next to 
    // each other so it looks like one continuous element. So we have to apply this 
    // color to all three
    .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline [class*="mat-form-field-outline"] {
        border-color: $boston-blue !important;
    }
    
    // the outline-gap element exists purely so material can make the border top blank when the floating label moves up there
    // we've removed the floating label so this is unnecessary now.
    .mat-form-field-outline-gap {
        border-color: currentColor !important; // currentColor comes from '~@angular/material/theming'
    } 

    /**
     * Chevron Styles
     */

    // an all-css no-images chevron arrow!
    .mat-select-arrow {
        width: 8px;
        height: 8px;
        border-left: none;
        border-right: 2px solid currentColor;
        border-bottom: 2px solid currentColor;
        border-top: none;
        transform: rotate(45deg);
    }

    // chevron color when select is open
    .mat-focused .mat-select-arrow {
        border-color: $boston-blue;
    }

    // chevron color on hover
    .mat-form-field-flex:hover .mat-select-arrow {
        border-color: $black;
    }
}

.mat-select-panel-wrap {
    // material displays the select dropdown in the middle of the select element by default. 
    // We need it to be directly underneath. 
    transform: translateX(29px) translateY(37px) !important;
    min-width: 235px !important;

    .mat-select-panel {
      min-width: 300px !important;
      max-width:300px !important;
      width: 300px !important;
      padding: 0 16px;
    }

    .mat-pseudo-checkbox {
        margin-left: 10px;
        color: $light-gray-2;
        background: $white;
    }
    
    .mat-optgroup-label {
        font-weight: bold;
    }

    .mat-pseudo-checkbox::after {
        border-bottom: 2px solid $boston-blue !important;
    }
    
    .mat-pseudo-checkbox-checked {
        background: $white !important;
        border-color: $boston-blue !important;
    }
    
    .mat-pseudo-checkbox-checked::after {
        border-left: 2px solid $boston-blue !important;
    }

    .mat-option-text {
        color: $black;
    }

    // It's not valid html to have nested optgroups, so we have to fake it.
    .outer-optgroup {
        margin-left: -10px;
        font-weight: bold;
        margin-bottom: -10px;
    }
}
