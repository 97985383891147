﻿
/* The Modal (background) */
.dialog-background {
  position: fixed; /* Stay in place */
  z-index: 10000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100vh; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.5); /* Black w/ opacity */
  &.under-title {
    z-index: 2;
  }
}

/* Modal Content/Box */
.dialog-content {
  text-align: center;
  background-color: #fefefe;
  border-radius: 6px;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 50%; /* Could be more or less, depending on screen size */

  &--wide {
    width: 95%;
    max-width: 1200px;
  }
}
