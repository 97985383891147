/* Legacy bootstrap 3 classes */

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}
