﻿@use '@angular/material' as mat;
/*Variables*/
@import 'partials/colors';
@import 'partials/typography';
@import 'partials/dimensions';
@import 'partials/loader';
/*Components*/
@import 'modules/modal.scss';
@import 'modules/tabs.scss';
@import '@danielmoncada/angular-datetime-picker/assets/style/picker.min.css';
/* URW DIN */
@import url('https://use.typekit.net/qws3ilp.css');
/* Roboto */
@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@include mat.elevation-classes();
@include mat.app-background();
// Custom Typography for Angular Material
// https://material.angular.io/guide/typography
$custom-typography: mat.m2-define-typography-config(
  $font-family: "'Source Sans Pro', helvetica, sans-serif",
  $headline-1: mat.m2-define-typography-level(32px, 48px, 700),
  $body-1: mat.m2-define-typography-level(16px, 24px, 500),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// Also see node_modules/@angular/material/_theming.scss
$emerald-portal-primary: mat.m2-define-palette(
  mat.$m2-blue-palette,
  A200,
  A100,
  A400
);
$emerald-portal-accent: mat.m2-define-palette(
  mat.$m2-blue-gray-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$emerald-portal-warn: mat.m2-define-palette(mat.$m2-orange-palette);

// Create the theme object (a Sass map containing all of the palettes).
$emerald-portal-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $emerald-portal-primary,
      accent: $emerald-portal-accent,
      warn: $emerald-portal-warn,
    ),
    typography: $custom-typography,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($emerald-portal-theme);

// Custom Themes for angular Material components
@import 'material/rn-multi-select.scss';

/******************
  Relay App Styles
*******************/

/* ensure journey print previews have correct background colors */
@media print {
  body {
    -webkit-print-color-adjust: exact;
  }
}

// Maximize the real estate for print
@page {
  margin: 0;
}

/*app specific styles*/
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: none;
  background-image: none;
  color: $white;
}

#copy_input {
  position: fixed;
  top: 0;
  opacity: 0;
}

/*app specific styles*/
html {
  min-height: 100%;
}

body {
  font-family: 'Source Sans Pro', helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: $white;
  min-height: 100%;
  &.grids-on {
    background-image: url('/assets/grid-120-blu.png');
    background-repeat: repeat;
  }
  &.login-bg-on {
    background-image: url('/assets/login-background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  &.preview {
    // Preview mode uses default scrolling.
    overflow-x: auto;
  }

  &.body--fixed {
    overflow: hidden; /* used to fix body scroll when a dialog is open */
  }
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  height: 100%;
  min-width: 1000px;
  -ms-overflow-style: scrollbar;
}

h1,
h2,
h3 {
  font-weight: 700;
  padding: 10px 10px 10px 0;
}

hr {
  border: none;
  background-color: $input_gray;
  height: 1px;
}

.no-text-selection {
  /* Prevent text selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.side-navbar-width-full {
  width: $side_nav_bar_width_full;
}

.margin-for-side-navbar-full {
  margin-left: $side_nav_bar_width_full;
}

.side-navbar-width-small {
  width: $side_navbar_width_small;
}

.margin-for-side-navbar-small {
  margin-left: $side_navbar_width_small;
}

.header-height {
  height: $header_height;
}

.content-box {
  height: calc(100vh - #{$header_height});
  @media print {
    height: 100%;
  }
}
.margin-for-header {
  margin-top: $header_height;
}

@mixin btn-primary-mixin {
  background-color: $primary_blue;
  border: 1px solid rgba(91, 90, 89, 0.2);
  border-radius: 4px;
  color: white;
  transition: all 0.3s ease;

  & i {
    color: white !important;
  }

  &:hover {
    background-color: $primary_hover;
    border-color: rgba(91, 90, 89, 0.6);
  }
}

@mixin btn-mixin {
  font-family: 'Din-Condensed', sans-serif;
  text-transform: uppercase;
  color: #546e7a;
  font-weight: 400;
  font-size: 16px;
  padding: 1px 0.45em;
  line-height: 1.33em;
  overflow: hidden;
  vertical-align: middle;

  &.btn-primary {
    @include btn-primary-mixin;
  }

  &.btn-warning {
    background-color: $orange;
    color: $white;
  }

  .container-fluid & {
    font-size: 1.2em;
  }

  &.btn-sm {
    font-size: 14px;
    padding: 0 8px;

    .icon {
      margin: 0 5px;
      line-height: 24px;
    }

    .container-fluid & {
      font-size: 1.2em;
      padding: 0 0.6em;
    }
  }

  &.btn-lg {
    font-size: 22px;
    line-height: 40px;

    .icon {
      line-height: 40px;
    }

    .container-fluid & {
      font-size: 1.8em;
    }
  }

  .icon {
    line-height: 31px;

    i {
      margin: 0.2em;
      padding: 0 !important;
    }

    &.icon-left {
      display: inline-block;
      border-style: none solid none none;
      border-color: rgba(91, 90, 89, 0.2);
      border-width: 1px;
      padding-left: 0px;
      margin-right: 0.3em;
      vertical-align: baseline;
      padding-right: 6px;
    }

    &.icon-right {
      display: inline-block;
      border-style: none none none solid;
      border-color: rgba(91, 90, 89, 0.2);
      border-width: 1px;
      padding-right: 0px;
      padding-left: 0.28em;
      margin-left: 0.3em;
    }
  }
}

.btn {
  @include btn-mixin;
}

.btn-remove {
  max-width: 100%;

  &:after {
    display: inline-block;
  }

  span {
    display: inline-block;
    max-width: none;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: top;
  }
}

/*Button group styles*/
.btn-group:not([dropdown]) {
  background-color: #aaa;
  border-radius: 4px;

  & .btn {
    background-color: #aaa;
    border-color: #adadad;
    border-radius: 4px;
    color: white;
    margin-left: -3px;

    &:active,
    &:focus {
      outline-color: transparent;
      z-index: 3 !important;
    }
  }

  & .btn.active {
    background-color: $primary_blue;
    border: 1px solid transparent;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
}

.screen > wire-preview > .card-phone {
  width: 277px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 30px;
  background: #fefff9;
  color: #363636;
  text-decoration: none;
  -moz-box-shadow: rgba(0, 0, 0, 0.19) 0 0 8px 0;
  -webkit-box-shadow: rgba(0, 0, 0, 0.19) 0 0 8px 0;
  box-shadow: rgba(0, 0, 0, 0.19) 0 0 8px 0;
}

/*Dynamic tags*/
span.dynamic-tag {
  background-color: $primary_blue;
  color: white;
  padding: 2px 5px;
  font-size: 13px;
  border-radius: 4px;
}

/*Forms*/
::-webkit-input-placeholder {
  font-size: 16;
  font-family: 'Source Sans Pro';
  color: $label_gray;
  opacity: 0.5;
}

::-moz-placeholder {
  font-size: 16;
  font-family: 'Source Sans Pro';
  color: $label_gray;
  opacity: 0.5;
}

/* firefox 19+ */
:-ms-input-placeholder {
  font-size: 16;
  font-family: 'Source Sans Pro';
  color: $label_gray;
  opacity: 0.5;
}

/* ie */
input:-moz-placeholder {
  font-size: 16;
  font-family: 'Source Sans Pro';
  color: $label_gray;
  opacity: 0.5;
}

.form-label {
  padding: 5px 0;
  font-weight: normal;
  text-align: end;
}

$dialog_border_radius: 6px;
.title-bar {
  height: 44px;
  background-color: #546f7a;
  color: $white;
  border-radius: $dialog_border_radius $dialog_border_radius 0 0;
  border-width: 2px;
  border-style: hidden hidden solid hidden;
  border-color: $accent;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 32px;
}

.form-control {
  height: 35px;
  font-size: 16px;
  font-weight: 400;
}

.form-group .form-control {
  background-color: #fff;

  &[disabled] {
    background-color: #eee;
  }
}

.has-error {
  position: relative;

  .error-msg {
    position: absolute;
    top: 17px;
    right: -10px;
    z-index: 3;
  }

  .form-control {
    border-color: $orange;

    &:focus {
      border-color: $orange;
    }
  }

  input,
  & .mce-tinymce {
    border: 1px solid $orange !important;
  }
}

.error-msg {
  color: #ff9100;
}

label {
  color: $label_gray;
  margin-top: 5px;
  font-size: 16px;
}

select.form-control {
  background-image: linear-gradient(135deg, #3693bf 60%, transparent 60%),
    linear-gradient(-135deg, #3693bf 60%, transparent 60%),
    linear-gradient($white, $white) !important;
  background-position: calc(100% - 23px), calc(100% - 30px), 0;
  background-size: 8px 8px, 8px 8px, 100%;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;

  &::-ms-expand {
    display: none;
  }
}

select.form-control.light {
  padding: 5px;
  background-color: $white;
  background-image: linear-gradient(135deg, #4d6570 60%, transparent 60%),
    linear-gradient(-135deg, #4d6570 60%, transparent 60%) !important;
  background-position: calc(100% - 13px), calc(100% - 20px);
  background-size: 8px 8px, 8px 8px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;

  &::-ms-expand {
    display: none;
  }
}

textarea {
  resize: none;
  border-radius: 4px;
  border-color: $input_gray;
}

.launch-button {
  padding: 0 22px;
}

.cancel-link {
  font-size: 12px;
  color: $secondary;
  cursor: pointer;

  &:hover {
    color: #333;
  }
}

i.fa-check-circle {
  color: $accent;
}

i.fa-exclamation-circle {
  color: $orange;
}

.wrdbrk {
  -ms-word-break: break-all;
  word-break: break-all;

  /* Non standard for webkit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

button.btn-primary.inactive,
button.btn-primary.inactive:hover {
  cursor: auto;
  border-color: #d1d1d1;
  background-color: #d1d1d1;
  pointer-events: none;
}

.heading {
  margin-top: 100px;
  margin-bottom: 40px;

  h2 {
    font-size: 26px;
    font-weight: bold;
    color: $label_gray;
    margin: 0;
    padding: 0 15px;
  }

  p {
    font-style: italic;
    font-size: 16px;
    color: $sub_title_gray;
    padding: 0 15px;
  }
}

.strong {
  font-weight: 700;
}

/* Print Styles */
@media print {
  app-side-nav,
  app-header,
  .fa {
    display: none;
  }
  div.content-box {
    margin-left: 0px;
  }
}

.clickable {
  cursor: pointer;
}

.re-collapsible {
  background-image: url(/assets/icons/icon-collapsible.png);
  background-size: 20px;
  background-repeat: no-repeat;
  margin: -2px -2px;
  width: 22px;
  height: 18px;
}

.re-new-message {
  background-image: url('/assets/icons/icon-NewMessage.png');
  background-size: 20px;
  background-repeat: no-repeat;
  margin: -2px -2px;
  width: 20px;
  height: 16px;
}

.re-email {
  background-image: url('/assets/icons/icon-EmailAction.png');
  background-size: 20px;
  background-repeat: no-repeat;
  margin: -2px -2px;
  width: 20px;
  height: 16px;
}

div.messageBox.selected {
  border: 3px solid $primary_blue !important;
  border-radius: 6px 6px 1px 1px;
  box-shadow: 0 0 8px 0 rgba(54, 147, 192, 0.2),
    0 6px 8px 0 rgba(54, 147, 192, 0.4);

  &.with-box-menu {
    border-radius: 6px 0px 1px 1px;
  }
}

.loading {
  visibility: hidden;
  opacity: 0;
}

.loaded {
  visibility: visible;
  -webkit-animation: fadeInFromNone 0.5s ease-out;
  -moz-animation: fadeInFromNone 0.5s ease-out;
  -o-animation: fadeInFromNone 0.5s ease-out;
  animation: fadeInFromNone 0.5s ease-out;
}

@-webkit-keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Search widget styles */
$search_height: 32px;
$search_width: 32px;

.search-field {
  padding: 5px;
  height: $search_height;
  vertical-align: middle;
  border-color: $input_gray;
  border-style: solid;
  border-width: 1px;
  outline: none;
}

.submit-search {
  padding: 5px;
  vertical-align: middle;
  width: $search_width;
  height: $search_height;
  border-radius: 0 4px 4px 0 !important;
  color: $white;
  i.fa {
    vertical-align: middle;
    text-align: center;
  }
}

/* Table styling for rich text */
table {
  background-color: $background_gray;

  th,
  td {
    padding: 0.5em;
    word-break: break-word;
  }
}

/* Custom date picker styles */
.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: $accent;
  border-radius: 4px;
}

.owl-dt-calendar-cell-active:focus
  > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
:not(.owl-dt-calendar-cell-disabled):hover
  > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  border-radius: 4px;
}

.owl-dt-control-button.owl-dt-timer-hour12-box {
  @include btn-mixin;
  @include btn-primary-mixin;
}

input.owl-dt-timer-input {
  text-align: center;
  font-size: 16px;
}

i.expand-collapse {
  font-size: 1.875em;
}

.expand-collapse {
  color: $primary_blue;
  font-size: 1.875em;
}

.turn90 {
  transform: rotate(90deg);
}

wire-preview-react {
  .ds-html-content h1 {
    font-size: 2em;
  }
  .ds-html-content h2 {
    font-size: 1.5em;
  }
  .ds-html-content h3 {
    font-size: 1.17em;
  }
  .ds-html-content h4 {
    font-size: 1em;
  }
  .ds-html-content h5 {
    font-size: 0.83em;
  }
  .ds-html-content h6 {
    font-size: 0.67em;
  }
  .ds-html-content {
    text-align: initial;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-refresh--loading {
  cursor: disabled;

  i {
    animation: rotate 1s infinite linear;
  }
}

/* two way chat loader */
.two-way-typing-prompt {
  text-align: center;
  background: #37474f;
  padding: 15px 10px;
  width: 55px;
  border-radius: 10px;
  margin-bottom: 10px;
  position: absolute;
  bottom: 120px;
  z-index: 1;
}
.two-way-typing-prompt span {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 20px;
  animation: loader 0.8s infinite alternate;
}
.two-way-typing-prompt span:nth-of-type(2) {
  animation-delay: 0.2s;
}
.two-way-typing-prompt span:nth-of-type(3) {
  animation-delay: 0.6s;
}
@keyframes loader {
  0% {
    opacity: 0.9;
    transform: scale(0.5);
  }
  100% {
    opacity: 0.1;
    transform: scale(1);
  }
}

